import { toFillFormat } from '@/helpers/toFillFormat';

function Comments() {
  return `
## Highlights
- ${toFillFormat('Answer relevant questions below and remove irrelevant questions')}
### Challenges
- ${toFillFormat('If applicable, why have we not achieved the Sprint Goal?')}
- ${toFillFormat('Any concerns about the team size, capacity or availabilities.')}
- ${toFillFormat('What are the biggest challenges achieved?')}
- ${toFillFormat('Is there anything you are particularly proud of?')}
- ${toFillFormat('Did any story turn out to be more challenging than expected? (1 to 3). Use the PBI Breakdown section down below.')}

### New lessons or frictions
- ${toFillFormat('Any lessons learned?')}
- ${toFillFormat('Are we efficient? Was there anything slowing us down?')}

### Action Items from last Sprint Retrospective.
- ${toFillFormat('What were the main action items from the last Sprint Retrospective?')}

### Other
- ${toFillFormat('Other comments can be about vacation, sick days, anything not covered above.')}
`;
}

export default Comments;
