import { defineStore } from 'pinia';
import { ATTACHMENTS_MAIN_PATH, NVENTIVE_ORG } from '@/settings';

const useReportDataStore = defineStore('reportData', {
  state: () => ({
    organization: NVENTIVE_ORG,
    timestamp: 0,
    folderName: '',
    project: null,
    wiki: null,
    team: null,
    iteration: null,
  }),
  getters: {
    folder(state) {
      let folderPath = ATTACHMENTS_MAIN_PATH + state.folderName;
      folderPath += (state.folderName.length ? '/' : '');
      return folderPath;
    },
  },
});

export default useReportDataStore;
