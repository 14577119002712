import { defineStore } from 'pinia';
import * as azdev from 'azure-devops-node-api';
import { PublicClientApplication } from '@azure/msal-browser';
import {
  ADO_URL,
  NVENTIVE_ORG,
  API_VERSION,
  msalConfig,
} from '@/settings';

const useApiStore = defineStore('api', {
  state: () => ({
    token: '', ORG_URL: '', coreApi: null, workApi: null, itemsApi: null, wikiApi: null, gitApi: null,
  }),
  actions: {
    getRequest(askScopes) {
      let scopes;
      if (askScopes === undefined) {
        scopes = ['499b84ac-1321-427f-aa17-267ca6975798/.default'];
      } else {
        scopes = askScopes;
      }
      const request = {
        scopes,
      };

      return request;
    },
    async getAccessToken(askScopes) {
      const request = this.getRequest(askScopes);
      const msalInstance = new PublicClientApplication(
        msalConfig,
      );
      try {
        const tokenResponse = await msalInstance.acquireTokenSilent(request);
        return tokenResponse.accessToken;
      } catch (error) {
        try {
          const tokenResponse = await msalInstance.acquireTokenPopup(request);
          return tokenResponse.accessToken;
        } catch (popupError) {
          return null;
        }
      }
    },
    async getBearerAccessToken(askScopes) {
      const request = this.getRequest(askScopes);
      const msalInstance = new PublicClientApplication(
        msalConfig,
      );
      try {
        const tokenResponse = await msalInstance.acquireTokenSilent(request);
        return tokenResponse.accessToken;
      } catch (error) {
        return null;
      }
    },
    async checkOrgnizationAndTokenValidity(token, orgName) {
      try {
        const response = await fetch(`https://dev.azure.com/${orgName}/_apis/projects?api-version=${API_VERSION}`, {
          headers: {
            Authorization: `Basic ${btoa(`:${token}`)}`,
            'Content-Type': 'application/json',
          },
        });
        if (response) {
          if (response.status === 200) {
            console.log('Organization exists and token is valid.');
            return true;
          }
          console.error('Organization and/or token not valid.');
          return false;
        }
        console.error('Response undefined or invalid.');
        return false;
      } catch (error) {
        console.error('Error in validation: ', error);
        return false;
      }
    },
    async updateToken(newToken, orgName = NVENTIVE_ORG) {
      if (this.token !== newToken) {
        this.token = newToken;
        this.ORG_URL = ADO_URL + orgName;

        let authHandler;
        let bearerToken = null;

        bearerToken = await this.getBearerAccessToken();

        if (orgName === NVENTIVE_ORG && bearerToken !== null) {
          // Bearer Token
          authHandler = azdev.getBearerHandler(this.token);
        } else {
          // Personal Access Token
          authHandler = azdev.getPersonalAccessTokenHandler(this.token);
        }
        const connection = new azdev.WebApi(this.ORG_URL, authHandler);

        await connection.getCoreApi().then((c) => {
          this.coreApi = c;
        });
        await connection.getWorkApi().then((w) => {
          this.workApi = w;
        });
        await connection.getWorkItemTrackingApi().then((wi) => {
          this.itemsApi = wi;
        });
      }
    },
  },
});

export default useApiStore;
