<template>
  <div id="app">
    <Header @onChangeWebApiToken="onChangeWebApiToken"/>
    <router-view />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import './App.scss';
import Header from '@/components/Header.vue';
import useApiStore from '@/stores/api';

export default {
  name: 'App',
  components: {
    Header,
  },
  computed: {
    ...mapStores(useApiStore),
  },
  methods: {
    onChangeWebApiToken(token) {
      this.apiStore.updateToken(token);
    },
  },
};
</script>
