import { toFillFormat } from '@/helpers/toFillFormat';

export function formatDate(date) {
  if (!date) return toFillFormat('YYYY-MM-DD');

  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${date.getFullYear()}-${month}-${day}`;
}

export function addDays(date, days) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + days,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
}

export function getBusinessDays(startDate, endDate) {
  const weekendDays = [0, 6];
  const currentDate = startDate;

  let businessDays = 0;
  while (currentDate <= endDate) {
    if (!weekendDays.includes(currentDate.getDay())) {
      businessDays += 1;
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return businessDays;
}
