<template>
  <div>
    <b-form-textarea v-model="markdown" rows="15"></b-form-textarea>
    <div class="buttons">
      <b-button variant="outline" @click="showModal">Preview</b-button>
      <b-button variant="primary" @click="copyToClipboard">Copy Report to Clipboard</b-button>
    </div>
    <b-modal ok-only size="xl" ref="preview" title="Preview">
      <div v-html="markdownToHtml"></div>
    </b-modal>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { marked } from 'marked';
import useReportDataStore from '@/stores/reportData';
import {
  IterationBurndown, IterationGoal, IterationVelocity, IterationCapacity, IterationRisks,
  IterationUserStories, ChangeRequests, Bugs, TimeValidation, Ceremonies, Comments, NextMilestone,
  Defects,
} from './reportElements';

export default {
  name: 'PMReport',
  props: {
    completedWorkItemsPerPerson: Array,
    iterationBugs: Array,
    iterationCapacities: Object,
    iterationData: Array,
    previousIterationsData: Array,
    iterationDefects: Array,
    iterationRisksData: Array,
    iterationImpediments: Array,
    iterationFeaturesData: Array,
    projectStartDate: Date,
    selectedIteration: Object,
    teamDaysOff: Object,
    timeOnBugs: Number,
    timeOnDefects: Number,
    isExternalReport: Boolean,
  },
  data() {
    return {
      markdown: '# Report',
    };
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.markdown);
      this.$toasted.show('Copied to clipboard!', {
        position: 'bottom-center',
        theme: 'outline',
        duration: 3000,
        type: 'success',
      });
    },
    showModal() {
      this.$refs.preview.show();
    },
    generateReport() {
      const iterationBurndown = IterationBurndown(this.reportDataStore);
      const iterationGoal = IterationGoal(this.reportDataStore);
      const iterationVelocity = IterationVelocity(this.iterationData);
      const iterationCapacity = IterationCapacity(
        this.iterationCapacities,
        this.selectedIteration,
        this.teamDaysOff,
      );
      const iterationRisks = IterationRisks(
        this.iterationRisksData,
        this.iterationImpediments,
      );
      const userStories = IterationUserStories(
        this.iterationData,
        this.previousIterationsData,
        this.isExternalReport,
      );
      const changeRequests = ChangeRequests(
        this.iterationData,
        this.previousIterationsData,
        this.isExternalReport,
      );
      const bugs = Bugs(
        this.iterationBugs,
        this.iterationData,
        this.iterationFeaturesData,
        this.timeOnBugs,
      );
      const defects = Defects(
        this.iterationDefects,
        this.iterationData,
        this.iterationFeaturesData,
        this.timeOnDefects,
      );
      const time = TimeValidation(this.completedWorkItemsPerPerson);
      const ceremonies = Ceremonies();
      const comments = Comments();
      const nextMilestone = NextMilestone();

      this.markdown = `
[[_TOC_]]

---
# Instructions (delete this section)
## Before the meeting
<span style="color:DeepPink">
1. Project Lead and ScrumMaster gather their thoughts on the sprint. You can use pink questions as a guide<br>
1. Prepare this report or a powerpoint presentation.
</span>

## During the meeting
<span style="color:DeepPink">
1. Present a demo of what was achieved. Frontend and Backend.<br>
2. Present the summary of the sprint. (report or pptx)<br><br>

Note: Stay focused on this sprint, overall project status is not the goal of this meeting.

</span>

# **${this.reportDataStore.iteration.name} Overview**
${iterationGoal}

${iterationVelocity}
${iterationCapacity}
${iterationBurndown}
---
${comments}

${nextMilestone}
---
${time}


---
# Detailed data
**No need to present below this line**

ScrumMaster and Project leads should use the data and questions below as a guide during their preparation.

---
${iterationRisks}
---
${userStories}
---
${changeRequests}
---
${bugs}
---
${defects}

${ceremonies}
`;
    },
  },
  created() {
    this.generateReport();
  },
  computed: {
    ...mapStores(useReportDataStore),
    markdownToHtml() {
      return marked(this.markdown);
    },
  },
};
</script>

<style>
  .buttons {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
  }
  .buttons button {
    margin-left: 1rem;
  }
</style>
