import { toFillFormat } from '@/helpers/toFillFormat';

function UserStoriesList(tableTitle, userStoriesData, previousUserStoriesData, isExternalReport) {
  let totalPlanning = 0;
  let totalSpentOnTasksCurrently = 0;
  let totalSpentOnTasksPreviously = 0;
  let totalSpentOnDefects = 0;
  let totalDifference = 0;
  let totalRemaining = 0;
  let totalNbDefects = 0;

  let userStoriesList = '';
  const userStories = [];
  userStoriesData.forEach((x) => {
    const onTasksCurrently = x.onTasks + x.onDefects;
    const planning = x.type === 'us' ? x.storyPoints : x.effort;
    let difference = (onTasksCurrently + x.remaining) - planning;

    let onTasksPreviously = 0;
    const previousData = previousUserStoriesData.find((y) => y.id === x.id);
    if (previousData !== undefined) {
      onTasksPreviously = previousData.onTasks + previousData.onDefects;
      totalSpentOnTasksPreviously += onTasksPreviously;
    }

    difference = Number(difference) + onTasksPreviously;

    userStories.push({
      id: x.id,
      url: x.url,
      title: x.title,
      state: x.state,
      estimated: planning,
      currentHours: onTasksCurrently,
      previousHours: onTasksPreviously,
      onDefects: x.onDefects,
      remaining: x.remaining,
      difference,
      nbDefects: x.nbDefects,
    });

    totalPlanning += planning;
    totalSpentOnTasksCurrently += onTasksCurrently;
    totalSpentOnDefects += x.onDefects;
    totalRemaining += x.remaining;
    totalDifference += difference;
    totalNbDefects += x.nbDefects;
  });

  userStories.sort((a, b) => (b.difference - a.difference));
  userStories.forEach((x) => {
    let differenceDisplay = x.difference.toFixed(2);

    if (x.difference > 0) {
      differenceDisplay = x.difference > 5 ? toFillFormat(differenceDisplay, 'Red', 'Bold') : toFillFormat(differenceDisplay, 'Black', 'Bold');
    }

    if (x.difference < -5) differenceDisplay = toFillFormat(differenceDisplay, 'ForestGreen');

    const userStoryId = isExternalReport ? `[#${x.id} **${x.title}** - ${x.state}](${x.url})` : `#${x.id}`;

    userStoriesList += `| ${userStoryId} | ${x.estimated.toFixed(2)} | ${x.currentHours.toFixed(2)} | ${x.previousHours.toFixed(2)} | ${x.onDefects.toFixed(2)} | ${x.remaining.toFixed(2)} | ${differenceDisplay} | ${x.nbDefects} |\n`;
  });

  const totalDifferenceDisplay = totalDifference > 0 ? `**${totalDifference.toFixed(2)}**` : totalDifference.toFixed(2);

  userStoriesList += `|**Total**| ${totalPlanning.toFixed(2)} | ${totalSpentOnTasksCurrently.toFixed(2)} | ${totalSpentOnTasksPreviously.toFixed(2)} | ${totalSpentOnDefects.toFixed(2)} | ${totalRemaining.toFixed(2)} | ${totalDifferenceDisplay} | ${totalNbDefects} |\n`;

  return `
| ${tableTitle} | Operational Estimate (hrs) | Completed - Tasks of Current Sprint (hrs) | Completed - Tasks from Previous Sprint (hrs) | Completed - Defects (hrs) | Remaining (hrs) | Difference (hrs) | Defects (#) |
|-----------|:-----------:|:-----------:|:-----------:|:-----------:|:-----------:|:-----------:|:-----------:|
${userStoriesList}
- **Completed - Tasks of Current Sprint** = Completed Hours on Tasks (Current) + Completed Hours on Defects (Current)
- **Completed - Tasks from Previous Sprint** = Completed Hours on Tasks (Previous) + Completed Hours on Defects (Previous)
- **Difference** = (Completed - Tasks of Current Sprint) + (Completed - Tasks from Previous Sprint) + Remaining - Operational Estimate
`;
}

export default UserStoriesList;
