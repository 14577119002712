import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import Toasted from 'vue-toasted';
import HighchartsVue from 'highcharts-vue';
import App from './App.vue';
import router from './router';
import BaseCard from './components/UI/BaseCard.vue';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toasted);
Vue.use(HighchartsVue);

Vue.component('base-card', BaseCard);

Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount('#app');
