<template>
  <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.99 310.49" class="logo">
    <g id="Nventive_Logo_fond_couleur" data-name="Nventive Logo fond couleur">
      <g id="Signe">
        <circle
          class="cls-1"
          cx="166.37"
          cy="138.57"
          r="117.84"
          transform="translate(-49.25 158.23) rotate(-45)"
        />
        <circle
          class="cls-2"
          cx="126.64"
          cy="179.73"
          r="117.84"
          transform="translate(-90 142.19) rotate(-45)"
        />
        <path
          class="cls-3"
          d="
            M64.27,79.75A117.85,
            117.85,0,0,0,228.73,
            238.58q2.61-4.54,
            4.87-9.39A117.84,
            117.84,0,0,0,64.27,79.75Z
          "
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'nv-logo',
};
</script>

<style>
  .logo {
    filter: drop-shadow(0px 0px 20px white);
  }

  .cls-1 {
    fill:#f9423a;
  }

  .cls-2{
    fill:#5bc5f2;
  }

  .cls-3{
    fill:#0d59d1;
  }
</style>
