<template>
  <Page title="Project Creator">
    <div v-if="!canAccess" class="error">
      You don't have the permissions in ADO to access this section.
    </div>
    <div v-if="canAccess">
      <b-input-group prepend="Name">
        <b-form-input
          type="text"
          v-model="projectName"
          :state="projectNameState"
          aria-describedby="input-live-help input-live-feedback"/>
        <b-form-invalid-feedback id="input-live-feedback">
          Mandatory field. Must not contain any special character.
        </b-form-invalid-feedback>
      </b-input-group>
      <b-input-group class="description" prepend="Description">
        <b-form-input type="text" v-model="projectDescription"/>
      </b-input-group>
      <b-input-group prepend="Type" >
        <b-form-select v-model="projectType"
                       :options="options"
                       :state="projectTypeState"
                       aria-describedby="input-live-help input-live-feedback">
          <b-form-select-option :value="null" disabled>
            Select an type
          </b-form-select-option>
        </b-form-select>
        <b-form-invalid-feedback id="input-live-feedback">
          Mandatory field.
        </b-form-invalid-feedback>
      </b-input-group>
      <div class="button">
        <b-button variant="primary"
                  @click="onClickCreate"
                  v-if="projectNameState && projectType">
          Create
        </b-button>
      </div>
    </div>
  </Page>
</template>

<script>
import { mapStores } from 'pinia';
import useApiStore from '@/stores/api';
import Page from '@/components/Page.vue';

export default {
  name: 'ProjectCreator',
  components: {
    Page,
  },
  computed: {
    ...mapStores(useApiStore),
    projectNameState() {
      if (!this.projectName) {
        return false;
      }
      const specialChars = '&$+,/:;=?@#<>[]{}|\\^%';
      return !specialChars
        .split('')
        .some((specialChar) => this.projectName.includes(specialChar));
    },
    projectTypeState() {
      return this.projectType !== null;
    },
  },
  data() {
    return {
      canAccess: true,
      projectName: '',
      projectDescription: '',
      projectType: null,
      options: [
        { value: 'adcc42ab-9882-485e-a3ed-7678f01f66bc', text: 'Agile' },
        { value: '1e492690-b4c8-446d-8fe6-465fc65feacf', text: 'Scrum' },
      ],
    };
  },
  methods: {
    onClickCreate() {
      const projectToCreate = {
        name: this.projectName,
        description: this.projectDescription,
        visibility: 0,
        capabilities: {
          versioncontrol: {
            sourceControlType: 'git',
          },
          processTemplate: {
            templateTypeId: this.projectType,
          },
        },
      };
      if (this.projectNameState) {
        this.apiStore.coreApi.queueCreateProject(projectToCreate);
      }
    },
  },
};
</script>

<style scoped>
  .button {
    display: flex;
    justify-content: flex-end;
  }

  .description{
    overflow-wrap: break-word;
  }
</style>
