import { toFillFormat } from '@/helpers/toFillFormat';
import CountTable from './CountTable';

function outputAverageBugs(typeTxt, data) {
  const total = data.length;
  const totalBugs = data.reduce((accumulator, us) => accumulator + us.nbBugs, 0);
  const bugsRatio = Number.isNaN(totalBugs / total) ? 0 : totalBugs / total;

  return `
- Average bugs per ${typeTxt}: ${bugsRatio.toFixed(2)}`;
}

function Bugs(iterationBugs, iterationData, iterationFeaturesData, totalSpentOnBugs) {
  const countTable = CountTable(iterationBugs);

  let averageOutput = '';

  const userStories = iterationData.filter((x) => x.type === 'us');
  const PBIs = iterationData.filter((x) => x.type === 'pbi');

  // If iteration have user stories or have no PBI to display user stories as default
  if (userStories.length > 0 || !PBIs.length) {
    averageOutput += outputAverageBugs('user story', userStories);
  }

  // If iteration have PBI
  if (PBIs.length > 0) {
    averageOutput += outputAverageBugs('PBI', PBIs);
  }

  // Features
  averageOutput += outputAverageBugs('feature', iterationFeaturesData);

  return `
### Bugs
- Number of bugs this Sprint: ${countTable.total}${averageOutput}
- Dev Time spent on bugs: ${totalSpentOnBugs.toFixed(2)}h

|Severity|Open Bugs (#)|Closed Bugs (#)|
${countTable.table}

${toFillFormat('Are we accumulating bugs?')}

${toFillFormat('Have we done a bug bash this month?')}
`;
}

export default Bugs;
