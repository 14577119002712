<template>
  <base-card>
    <header class="title-container">
      <h1 class="title">{{ title }}</h1>
      <NvLogo class="nv-logo"/>
    </header>
    <slot></slot>
  </base-card>
</template>

<script>
import NvLogo from './UI/NvLogo.vue';

export default {
  name: 'Page',
  props: {
    title: String,
  },
  components: {
    NvLogo,
  },
};
</script>

<style scoped>
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.nv-logo {
  height: 40px;
}
</style>
