// --------------------- Microsoft Authentication ---------------------
const AzureADClientID = '8a5f8572-85e6-4c45-b9b7-eae490c4ac07';
const AzureADTenantID = '6d53ef61-b6d1-4150-ae0b-43b90e75e0cd';
export const msalConfig = {
  auth: {
    clientId: AzureADClientID,
    authority:
    `https://login.microsoftonline.com/${AzureADTenantID}`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};
/* See :
  https://devblogs.microsoft.com/azure-sdk/vue-js-user-authentication/
  https://docs.microsoft.com/en-us/azure/devops/organizations/accounts/manage-personal-access-tokens-via-api?view=azure-devops
*/

// ---------- Azure DevOps Services REST API ---------
// The key that contains all the organization names and associated PAT
export const API_VERSION = '2.0';

// ------------------- Local Storage -----------------
// The key that contains all the organization names and associated PAT
export const LOCAL_STORAGE_KEY = 'organizations';

// ------------------- Organization ------------------
// your collection url
export const NVENTIVE_ORG = 'nventive';
export const ADO_URL = 'https://dev.azure.com/';

// --------------------- Project ---------------------
// Internal project ID
// Used if selected project don't have wiki then use this project wiki
export const DEFAULT_PROJECT_ID = 'd424e903-92fb-4270-96ef-17067ed04454';

// --------------------- Charts ---------------------
/** Maximum number of weeks in burndown chart */
export const MAX_BURNDOWN_WEEKS = 16;
/** Maximum number of iterations to show in a chart */
export const MAX_ITERATIONS = 15;

export const ChartFileNames = Object.freeze({
  IT_BUG_V_TASK: 'iteration-bug-vs-task-chart',
  PR_BUG_V_TASK: 'project-bug-vs-task-chart',
  PR_BURNDOWN: 'project-burndown-chart',
  PR_ESTIMATION_V_REAL: 'project-estimate-vs-real-chart',
  PR_ESTIMATION_V_REAL_EV: 'project-estimate-vs-real-evolution-chart',
  PR_US_STATE: 'user-story-state-chart',
});

export const ATTACHMENTS_MAIN_PATH = '/.attachments/reports/';

export const STATE_COLORS = new Map([
  ['Active', '#007ACC'],
  ['Active Testing', '#F58B1F'],
  ['Approved', '#CCCCCC'],
  ['Closed', '#339947'],
  ['Committed', '#007ACC'],
  ['Committed Testable', '#007ACC'],
  ['Committed Tested', '#007ACC'],
  ['Defined', '#43B4D5'],
  ['Done', '#339947'],
  ['In Progress', '#0477BF'],
  ['Needs Attention', '#F58B1F'], // TODO : can be red #A60D29
  ['New', '#CCCCCC'],
  ['Ready', '#FBE74B'],
  ['Ready to Build', '#71338D'],
  ['Ready to Test', '#F58B1F'],
  ['Removed', '#3D3D3D'],
  ['Resolved', '#007ACC'],
  ['To Be Reviewed', '#F58B1F'],
  ['To Do', '#B2B2B2'],
  ['Triaged', '#009CCC'],
]);

export const CLOSED_STATES = ['Closed', 'Done', 'Removed', 'Resolved'];

// --------------------- Data ---------------------
export const TimeFrames = Object.freeze({
  PAST: 0,
  CURRENT: 1,
  FUTURE: 2,
});
