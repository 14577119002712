import { toFillFormat } from '@/helpers/toFillFormat';

function Ceremonies() {
  return `
### Ceremonies
${toFillFormat('Have all ceremonies been held?')}
- Daily Standup.
- Sprint Planning.
- Sprint Retrospective.
- Sprint Demo and Review.
`;
}

export default Ceremonies;
