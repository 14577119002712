function IterationBurndown(reportDataStore) {
  const {
    organization, project, team, iteration,
  } = reportDataStore;
  const projectId = project.id;
  const teamId = team.id;
  const iterationId = iteration.id;

  const burndownPath = `https://dev.azure.com/${organization}/${projectId}/${teamId}/_apis/work/iterations/${iterationId}/chartimages/burndown?width=675&height=300&showDetails=true`;

  return `
![Burndown](${burndownPath})`;
}

export default IterationBurndown;
