import { toFillFormat } from '@/helpers/toFillFormat';
import CountTable from './CountTable';

function outputAverageDefects(typeTxt, data) {
  const total = data.length;
  const totalDefects = data.reduce((accumulator, us) => accumulator + us.nbDefects, 0);
  const defectsRatio = Number.isNaN(totalDefects / total) ? 0 : totalDefects / total;

  return `
- Average defects per ${typeTxt}: ${defectsRatio.toFixed(2)}`;
}

function Defects(iterationDefects, iterationData, iterationFeaturesData, totalSpentOnDefects) {
  const countTable = CountTable(iterationDefects);

  let averageOutput = '';

  const userStories = iterationData.filter((x) => x.type === 'us');
  const PBIs = iterationData.filter((x) => x.type === 'pbi');

  // If iteration have user stories or have no PBI to display user stories as default
  if (userStories.length > 0 || !PBIs.length) {
    averageOutput += outputAverageDefects('user story', userStories);
  }

  // If iteration have PBI
  if (PBIs.length > 0) {
    averageOutput += outputAverageDefects('PBI', PBIs);
  }

  // Features
  averageOutput += outputAverageDefects('feature', iterationFeaturesData);

  if (iterationDefects.length === 0) {
    return '';
  }

  return `
### Defects - ${toFillFormat('Sprintly')}
- Number of defects this Sprint: ${countTable.total}${averageOutput}
- Dev Time spent on defects: ${totalSpentOnDefects.toFixed(2)}h

|Severity|Open Defects (#)|Closed Defects (#)|
${countTable.table}
---
`;
}

export default Defects;
