import { getBusinessDays } from '@/helpers/Dates';

function IterationCapacity(capacities, iteration, teamDaysOff) {
  const startDate = new Date(iteration.attributes.startDate);
  const finishDate = new Date(iteration.attributes.finishDate);
  const daysInIteration = getBusinessDays(startDate, finishDate);

  let teamDaysOffInIteration = 0;
  if (teamDaysOff && teamDaysOff.daysOff.length > 0) {
    for (let i = 0; i < teamDaysOff.daysOff.length; i += 1) {
      const teamDaysOffStart = new Date(teamDaysOff.daysOff[i].start);
      const teamDaysOffEnd = new Date(teamDaysOff.daysOff[i].end);

      teamDaysOffInIteration += getBusinessDays(teamDaysOffStart, teamDaysOffEnd);
    }
  }

  let totalCapacityPerIteration = 0;
  if (capacities) {
    for (let i = 0; i < capacities.teamMembers.length; i += 1) {
      let numberOfDaysOff = 0;

      if (capacities.teamMembers[i].daysOff.length > 0) {
        for (let j = 0; j < capacities.teamMembers[i].daysOff.length; j += 1) {
          const daysOffStart = new Date(capacities.teamMembers[i].daysOff[j].start);
          const daysOffEnd = new Date(capacities.teamMembers[i].daysOff[j].end);

          numberOfDaysOff += getBusinessDays(daysOffStart, daysOffEnd);
        }
      }

      for (let k = 0; k < capacities.teamMembers[i].activities.length; k += 1) {
        // eslint-disable-next-line max-len
        totalCapacityPerIteration += (daysInIteration - numberOfDaysOff - teamDaysOffInIteration) * capacities.teamMembers[i].activities[k].capacityPerDay;
      }
    }
  }

  return `
Initial capacity planned in Azure Devops for the sprint was **${totalCapacityPerIteration.toFixed(2)} hours**`;
}

export default IterationCapacity;
