<template>
  <Page title="Report Generator">
    <ProjectList />
  </Page>
</template>

<script>
import { mapStores } from 'pinia';
import Page from '@/components/Page.vue';
import ProjectList from '@/components/ProjectList.vue';
import useApiStore from '@/stores/api';

export default {
  name: 'ProjectGenerator',
  components: {
    Page,
    ProjectList,
  },
  computed: {
    ...mapStores(useApiStore),
  },
};
</script>

<style scoped>

</style>
