import { toFillFormat } from '@/helpers/toFillFormat';

function TimeValidation(completedWorkItemsPersons) {
  let totalTime = 0;
  let personsList = '';

  const peopleByTime = completedWorkItemsPersons.sort((a, b) => (b.completed - a.completed));
  peopleByTime.forEach((x) => {
    personsList += `|${x.name}|${parseFloat(x.completed.toFixed(2))}|    |\n`;
    totalTime += x.completed;
  });
  personsList += `|**Total**|${parseFloat(totalTime.toFixed(2))}|0|`;

  return `
## Time Validation (Azure DevOps vs Harvest)
${toFillFormat('Fill in the missing fields under Harvest Time. Explain significant differences.')}

|Team member|AzDO Time (h)|Harvest Time (h)|Explanation|
|:----------|:----------:|:--------------:|:--------------:|
${personsList}


${toFillFormat('Highlight it if the difference with Harvest is more than you expect.')}
`;
}

export default TimeValidation;
