import Vue from 'vue';
import VueRouter from 'vue-router';
import ProjectCreator from '@/views/ProjectCreator.vue';
import ReportGenerator from '@/views/ReportGenerator.vue';

Vue.use(VueRouter);

// TODO: add a route to access a certain project directly

const routes = [
  {
    path: '/',
    name: 'report-generator',
    component: ReportGenerator,
  },
  {
    path: '/create',
    ame: 'project-creator',
    component: ProjectCreator,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
