<template>
  <b-navbar type="dark" variant="transparent">
    <b-navbar-brand to="/">
      <NvLogo style="height:30px"/>
      Azure DevOps Toolkit
    </b-navbar-brand>
    <b-navbar-nav>
      <b-nav-item to="/">Report Generator</b-nav-item>
      <b-collapse>
        <b-nav-item to="/create">Project Creator</b-nav-item>
      </b-collapse>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <b-nav-text v-if="account">
        <b-icon-person-fill/>
        {{ account.name }}
      </b-nav-text>
      <b-nav-item v-if="!account" @click="SignIn">Log In</b-nav-item>
      <b-nav-item v-if="account" @click="SignOut">Log Out</b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapStores } from 'pinia';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@/settings';
import useApiStore from '@/stores/api';
import NvLogo from './UI/NvLogo.vue';

export default {
  name: 'Header',
  data() {
    return {
      account: null,
      webApiToken: null,
      msalInstance: null,
    };
  },
  components: {
    NvLogo,
  },
  async created() {
    this.msalInstance = new PublicClientApplication(
      msalConfig,
    );
    const activeAccount = this.msalInstance.getActiveAccount();
    if (activeAccount) {
      await this.setLoggedIn(activeAccount);
    }
  },
  computed: {
    ...mapStores(useApiStore),
  },
  methods: {
    async setLoggedIn(account) {
      this.account = account;
      this.webApiToken = await this.apiStore.getAccessToken();

      if (this.webApiToken === null) {
        this.$toasted.show('Error during acquire token, you must allow popups.', {
          position: 'bottom-center',
          theme: 'outline',
          duration: 3000,
          type: 'error',
        });
      }
    },
    async SignIn() {
      await this.msalInstance
        .loginPopup({})
        .then(() => {
          const myAccounts = this.msalInstance.getAllAccounts();
          if (myAccounts.length) {
            const account = myAccounts[0];
            this.msalInstance.setActiveAccount(account);
            this.setLoggedIn(account);
          }
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      await this.msalInstance
        .logoutPopup({})
        .then(() => {
          this.account = null;
          this.webApiToken = null;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  watch: {
    webApiToken(val) {
      this.$emit('onChangeWebApiToken', val);
    },
  },
};
</script>

<style scoped>

</style>
