import { toFillFormat } from '@/helpers/toFillFormat';

function IterationGoal() {
  return `
## Sprint Goal
- ${toFillFormat('Enter Sprint Goal here.')}

Was it achieved?
- ${toFillFormat('Yes or No')}`;
}

export default IterationGoal;
