import { toFillFormat } from '@/helpers/toFillFormat';
import CountTable from './CountTable';

function IterationRisks(iterationRisksData, iterationImpediments) {
  let risksList = '';
  iterationRisksData.forEach((x) => {
    risksList += `- #${x.id}\n`;
  });
  if (!risksList) risksList = toFillFormat('None', 'grey');

  const countTable = CountTable(iterationImpediments);

  return `
### Risks + List of impediments
${risksList}

In total, there are **${countTable.total}** impediments.

|Priority|Open Impediments (#)|Closed Impediments (#)|
${countTable.table}`;
}

export default IterationRisks;
