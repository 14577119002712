import UserStoriesList from './UserStoriesList';

function ChangeRequests(iterationData, previousIterationData, isExternalReport) {
  const changeRequests = iterationData.filter((x) => x.isCR);
  const previousChangeRequests = previousIterationData.filter((x) => x.isCR);

  // User stories as default
  let typeTxt = 'User stories';

  const userStories = iterationData.filter((x) => x.type === 'us');
  const PBIs = iterationData.filter((x) => x.type === 'pbi');

  // If iteration have PBI
  if (PBIs.length > 0) {
    typeTxt = 'PBIs';

    // If iteration have user stories and PBI
    if (userStories.length > 0) {
      typeTxt = 'User stories/PBIs';
    }
  }

  return `
### Change Requests Breakdown
- **N.B.** ${typeTxt} must be tagged with \`CR\` or \`Change Request\` to appear here.

${UserStoriesList('Change Request', changeRequests, previousChangeRequests, isExternalReport)}`;
}

export default ChangeRequests;
