import CryptoJS from 'crypto-js';

export function encryptToken(token, secretKey) {
  const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
  return encryptedToken;
}

export function decryptToken(encryptedToken, secretKey) {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return decryptedToken;
}
