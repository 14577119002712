function CountTable(data, nbRows = 4) {
  let totalOpen = 0;
  let totalClosed = 0;
  let table = '|:------:|:---|:-----|\n';
  for (let i = 0; i < nbRows; i += 1) {
    let open = 0;
    let closed = 0;
    if (typeof data[i] !== 'undefined') {
      open = data[i].open;
      closed = data[i].closed;
    }

    totalOpen += open;
    totalClosed += closed;
    table += `|**${i + 1}**|${open}|${closed}|\n`;
  }
  table += `|**Total**|${totalOpen}|${totalClosed}|`;
  const total = totalOpen + totalClosed;

  return {
    total,
    table,
  };
}

export default CountTable;
