function outputVelocity(typeTxt, sum) {
  return `Sum of ${typeTxt} completed is **${sum.toFixed(0)}** hour-points.`;
}

// Only count if from current iteration and not removed and closed
function IterationVelocity(iterationData) {
  let output = '';

  const userStories = iterationData.filter((x) => x.type === 'us');
  const PBIs = iterationData.filter((x) => x.type === 'pbi');

  // If iteration have user stories or have no PBI to display user stories as default
  if (userStories.length > 0 || !PBIs.length) {
    const sum = userStories.filter((x) => x.currentIteration && x.state !== 'Removed' && x.isClosed)
      .reduce((accumulator, us) => accumulator + us.storyPoints, 0);

    output += outputVelocity('User Story points', sum);
  }

  // If iteration have PBI
  if (PBIs.length > 0) {
    const sum = PBIs.filter((x) => x.currentIteration && x.state !== 'Removed' && x.isClosed)
      .reduce((accumulator, us) => accumulator + us.effort, 0);

    output += `${output ? `

` : ''}${outputVelocity('PBI Effort', sum)}`;
  }

  return `Velocity: ${output}`;
}

export default IterationVelocity;
