export function chunkArray(arr, chunkSize = 200) {
  const chunks = [];
  for (let index = 0; index < arr.length; index += chunkSize) {
    const chunk = arr.slice(index, index + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}

export default chunkArray;
