import { toFillFormat } from '@/helpers/toFillFormat';
import UserStoriesList from './UserStoriesList';

function outputUserStories(title, data, previousData, isExternalReport) {
  return `
### ${title} Breakdown
${toFillFormat('**Do not present this chart**, use it to highlight problematic or particularly successful stories.')}
${UserStoriesList(title, data, previousData, isExternalReport)}`;
}

function IterationUserStories(iterationData, previousIterationData, isExternalReport) {
  let output = '';

  const userStories = iterationData.filter((x) => x.type === 'us');
  const PBIs = iterationData.filter((x) => x.type === 'pbi');
  const previousUserStories = previousIterationData.filter((x) => x.type === 'us');
  const previousPBIs = previousIterationData.filter((x) => x.type === 'pbi');

  // If iteration have user stories or have no PBI to display user stories as default
  if (userStories.length > 0 || !PBIs.length) {
    output += outputUserStories('User Story', userStories, previousUserStories, isExternalReport);
  }

  // If iteration have PBI
  if (PBIs.length > 0) {
    output += `${output ? `
` : ''}${outputUserStories('PBI', PBIs, previousPBIs, isExternalReport)}`;
  }

  return output;
}

export default IterationUserStories;
