import { toFillFormat } from '@/helpers/toFillFormat';

function NextMilestone() {
  return `
## Next Milestone
- ${toFillFormat('When is the next milestone release?')}
- ${toFillFormat('Are we on track?')}
`;
}

export default NextMilestone;
