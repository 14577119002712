<template>
  <div>
    <!-- Organization -->
    <b-input-group prepend="Organization">
      <b-form-select v-model="selectedOrganization">
        <b-form-select-option :value="null" disabled>
          Select an organization
        </b-form-select-option>
        <b-form-select-option
          v-for="organization in organizationOptions"
          :value="organization.value"
          :key="organization.key"
        >
          {{organization.value}}
        </b-form-select-option>
      </b-form-select>
    </b-input-group>
    <div class="btn-container">
      <div class="btn-wrapper">
        <b-button
          type="button"
          variant="outline"
          v-b-modal.organization-modal>
          + Add organization
        </b-button>
      </div>
      <div class="btn-wrapper">
        <b-button
          v-if="selectedOrganization"
          type="button"
          variant="outline"
          v-b-modal.update-pat-modal>
          Update Personal Access Token
        </b-button>
    </div>
  </div>
    <!-- Project -->
    <b-input-group prepend="Project">
      <b-form-select v-model="selectedProject" :disabled="!selectedOrganization">
        <b-form-select-option :value="null" disabled>
          Select a project
        </b-form-select-option>
        <b-form-select-option
          v-for="project in projectOptions"
          :value="project.value"
          :key="project.value.id"
        >
          {{project.text}}
        </b-form-select-option>
      </b-form-select>
    </b-input-group>
    <!-- Team -->
    <b-input-group prepend="Team">
      <b-form-select v-model="selectedTeam" :disabled="!selectedProject">
        <b-form-select-option :value="null" disabled>
          Select a team
        </b-form-select-option>
        <b-form-select-option
          v-for="team in teamsOptions"
          :value="team.value"
          :key="team.value.id"
        >
          {{team.text}}
        </b-form-select-option>
      </b-form-select>
    </b-input-group>
    <!-- Iteration -->
    <b-input-group prepend="Iteration">
      <b-form-select v-model="selectedIteration" :disabled="!selectedTeam">
        <b-form-select-option :value="null" disabled>
          Select an iteration
        </b-form-select-option>
        <b-form-select-option
          v-for="iteration in iterationOptions"
          :value="iteration.value"
          :key="iteration.value.id"
        >
          {{iteration.text}}
        </b-form-select-option>
      </b-form-select>
    </b-input-group>
    <!-- CLear button -->
    <div class="btn-wrapper">
      <b-button
        type="button"
        variant="clear"
        @click="clearAllData"
        :disabled="!selectedProject">
        Clear
      </b-button>
    </div>
    <div v-if="loading || (!!selectedIteration && !chartsSaved)"
         class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="btn-container" v-if="selectedOrganization">
      <div class="btn-wrapper">
        <b-button
          type="button"
          @click="showInternalMarkdown"
          :variant="internalBtnVariant">
          Paste into the {{selectedOrganization}} organization
        </b-button>
      </div>
      <div class="btn-wrapper">
        <b-button
          type="button"
          @click="showExternalMarkdown"
          :variant="externalBtnVariant">
          Paste into another organization
        </b-button>
      </div>
    </div>
    <!-- Modals -->
    <b-modal
      id="organization-modal"
      title="Add Organization"
      ok-title="Submit"
      @ok="fetchOrganizationData"
      @hidden="clearOrganizationInput">
      <div class="input-icon">
        <b-form-input
          v-model="organizationName"
          placeholder="Enter the organization name"
          :state="organizationInputError">
        </b-form-input>
        <div class="tooltip-icon" id="name-tooltip">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <!-- eslint-disable-next-line max-len -->
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
          </svg>
        </div>
        <b-tooltip target="name-tooltip" triggers="hover">
          The organization name is the part in the URL that follows the domain name. In this example : 'https://dev.azure.com/{your_organization_name}', the domain name is 'dev.azure.com' and the organization name is '{your_organization_name}'. Copy that part and paste it here.
        </b-tooltip>
      </div>
      <div class="input-icon">
        <b-form-input
          class="last"
          v-model="personalAccessToken"
          placeholder="Enter the personal access token"
          :state="organizationInputError">
        </b-form-input>
        <div
          @click="openTab"
          class="tooltip-icon"
          id="pat-tooltip"
          data-html="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <!-- eslint-disable-next-line max-len -->
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
          </svg>
        </div>
        <b-tooltip  target="pat-tooltip" triggers="hover">
          <!-- eslint-disable-next-line max-len -->
          Click here to see how to create a new personal access token.
        </b-tooltip>
      </div>
      <div class="helper-text">
        <!-- eslint-disable-next-line max-len -->
        When creating a new token, under Scopes section, select "Custom defined". Find "Work Items" and only select "Read".
      </div>
      <div class="error" v-if="organizationInputError === false" variant="danger">
        Organization and/or token not invalid.
      </div>
    </b-modal>
    <b-modal
      id="update-pat-modal"
      title="Update Personal Access Token"
      ok-title="Save"
      @ok="updatePersonalAccessToken"
      @hidden="clearNewPersonalAccessToken">
      <h5>
        {{this.selectedOrganization}}
      </h5>
      <b-form-input
        class="last"
        v-model="newPersonalAccessToken"
        placeholder="Enter the new personal access token"
        :state="tokenUpdateInputError">
      </b-form-input>
      <div class="error" v-if="tokenUpdateInputError === false" variant="danger">
        Token is invalid.
      </div>
    </b-modal>
    <!-- PMReport -->
    <PMReport
      v-if="!!selectedIteration && chartsSaved && !loading"
      :completedWorkItemsPerPerson="peopleTimeStats"
      :iterationBugs="iterationBugs"
      :iterationCapacities="iterationCapacities"
      :iterationData="iterationData"
      :previousIterationsData="previousIterationsData"
      :iterationDefects="iterationDefects"
      :iterationFeaturesData="iterationFeaturesData"
      :iterationRisksData="iterationRisksData"
      :iterationImpediments="iterationImpediments"
      :projectStartDate="reportStartDate"
      :selectedIteration="selectedIteration"
      :teamDaysOff="teamDaysOff"
      :timeOnBugs="timeOnBugs"
      :timeOnDefects="timeOnDefects"
      :isExternalReport="isExternalReport"
    />
  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint no-underscore-dangle: ["error", { "allow": ["_links"] }] */

import { mapStores } from 'pinia';
import {
  MAX_BURNDOWN_WEEKS,
  CLOSED_STATES,
  LOCAL_STORAGE_KEY,
  NVENTIVE_ORG,
  TimeFrames,
} from '@/settings';
import { addDays } from '@/helpers/Dates';
import { chunkArray } from '@/helpers/chunkArray';
import {
  encryptToken,
  decryptToken,
} from '@/helpers/encryptDecrypt';
import useReportDataStore from '@/stores/reportData';
import useApiStore from '@/stores/api';
import PMReport from './pmReport/PMReport.vue';

export default {
  name: 'ProjectList',
  components: {
    PMReport,
  },
  data() {
    return {
      chartsSaved: true, // No more custom chart so set as true by default
      loading: false,
      /** Dropdown options */
      organizationOptions: [],
      projectOptions: [],
      teamsOptions: null,
      iterationOptions: null,
      /** Selected options */
      selectedOrganization: null,
      selectedProject: null,
      selectedTeam: null,
      selectedIteration: null,
      teamDaysOff: null,
      /** Time stats */
      projectTimeStats: {},
      iterationsTimeStats: [],
      peopleTimeStats: [],
      /** Other */
      teamIterations: [],
      wiki: null,
      reportStartDate: null,
      userStoriesPerState: [],
      burndownData: [],
      iterationBugs: [],
      iterationDefects: [],
      iterationCapacities: null,
      iterationData: [],
      previousIterationsData: [],
      iterationFeaturesData: [],
      iterationRisksData: [],
      iterationImpediments: [],
      timeOnBugs: 0,
      timeOnDefects: 0,
      organizationName: '',
      personalAccessToken: '',
      newPersonalAccessToken: '',
      organizationInputError: null,
      tokenUpdateInputError: null,
      isExternalReport: false,
      internalBtnVariant: 'primary',
      externalBtnVariant: 'outline',
    };
  },
  computed: {
    ...mapStores(useReportDataStore),
    ...mapStores(useApiStore),
  },
  methods: {
    onChangeSaveStatus(isSaved) {
      this.chartsSaved = isSaved;
    },
    async fetchOrganizationData(e) {
      e.preventDefault();
      this.organizationName = this.organizationName.trim();

      let orgAndTokenAreValid = false;
      let bearerToken = null;

      if (this.organizationName === NVENTIVE_ORG) {
        bearerToken = await this.apiStore.getBearerAccessToken();
      }

      // eslint-disable-next-line max-len
      orgAndTokenAreValid = await this.apiStore.checkOrgnizationAndTokenValidity(this.personalAccessToken, this.organizationName);

      if (orgAndTokenAreValid === true) {
        if (bearerToken === null) {
          this.updateOrganizationOptions();

          this.$toasted.show('New organization added successfully!', {
            position: 'bottom-center',
            theme: 'outline',
            duration: 3000,
            type: 'success',
          });
        } else {
          this.$toasted.show('There is already a bearer token for this organization. No need for a PAT.', {
            position: 'bottom-center',
            theme: 'outline',
            duration: 3000,
            type: 'info',
          });
        }
        this.clearOrganizationInput();
      } else {
        this.organizationInputError = false;
      }
    },
    updateOrganizationOptions() {
      let organization = {};
      let organizations = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (organizations !== null) {
        organizations = JSON.parse(organizations);
      } else {
        organizations = {};
      }

      const secretKey = process.env.VUE_APP_ENCRYPTION_DECRYPTION_KEY;
      const encryptedPAT = encryptToken(this.personalAccessToken, secretKey);

      organizations[this.organizationName] = encryptedPAT;
      organization = {
        value: this.organizationName,
        key: encryptedPAT,
      };

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(organizations));
      this.organizationOptions.push(organization);
      this.organizationOptions.sort((a, b) => a.value.localeCompare(b.value));
    },
    async updatePersonalAccessToken(e) {
      e.preventDefault();
      let organizations;
      let orgAndTokenAreValid = false;
      // eslint-disable-next-line max-len
      orgAndTokenAreValid = await this.apiStore.checkOrgnizationAndTokenValidity(this.newPersonalAccessToken, this.selectedOrganization);

      if (orgAndTokenAreValid === true) {
        organizations = localStorage.getItem(LOCAL_STORAGE_KEY);

        if (organizations !== null) {
          organizations = JSON.parse(organizations);
        } else {
          organizations = {};
        }

        const secretKey = process.env.VUE_APP_ENCRYPTION_DECRYPTION_KEY;
        const encryptedPAT = encryptToken(this.newPersonalAccessToken, secretKey);

        organizations[this.selectedOrganization] = encryptedPAT;
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(organizations));
        this.clearNewPersonalAccessToken();

        this.$toasted.show('Token updated successfully!', {
          position: 'bottom-center',
          theme: 'outline',
          duration: 3000,
          type: 'success',
        });
      } else {
        this.tokenUpdateInputError = false;
      }
    },
    async getOrganizationOptions() {
      let orgKeys = null;
      let bearerToken = null;
      let organizations = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (organizations !== null) {
        organizations = JSON.parse(organizations);
        this.organizationOptions = Object.keys(organizations)
          .map((key) => ({
            key: organizations[key],
            value: key,
          }));
        orgKeys = Object.keys(organizations);
      }

      if (organizations === null || (orgKeys !== null && !orgKeys.includes(NVENTIVE_ORG))) {
        bearerToken = await this.apiStore.getBearerAccessToken();
        if (bearerToken !== null) {
          const organization = {
            value: NVENTIVE_ORG,
            key: bearerToken,
          };
          this.organizationOptions.push(organization);
        }
      }

      this.organizationOptions.sort((a, b) => a.value.localeCompare(b.value));
    },
    /** Assigns an alphabetically-sorted simplified list of projects to this.projectOptions */
    getProjectOptions() {
      if (this.apiStore.coreApi === null) return;

      this.apiStore.coreApi.getProjects('', 1000).then((result) => {
        result.sort((a, b) => a.name.localeCompare(b.name));
        this.projectOptions = result.map((r) => ({
          value: r,
          text: r.name,
        }));
      });
    },
    /**
     * Assigns a simplified alphabetically-sorted list of teams to this.teamOptions
     */
    getTeams() {
      if (this.apiStore.coreApi === null) return;

      this.apiStore.coreApi.getTeams(this.selectedProject.id)
        .then((result) => {
          result.sort((a, b) => a.name.localeCompare(b.name));
          this.teamsOptions = result.map((r) => ({
            value: r,
            text: r.name,
          }));
        });
    },
    /**
     * Assigns a date-sorted list of iterations to this.teamIterations
     * and a simplified version to this.iterationOptions
     * @returns {Promise<TeamSettingsIteration[]>}
     */
    getIterations() {
      return this.apiStore.workApi.getTeamIterations({
        projectId: this.selectedProject.id,
        teamId: this.selectedTeam.id,
      }).then((result) => {
        const iterations = result.filter((it) => it.attributes.timeFrame !== TimeFrames.FUTURE);
        iterations.sort((a, b) => ((a.attributes.startDate > b.attributes.startDate) ? 1 : -1));
        this.teamIterations = iterations;
        this.iterationOptions = iterations.map((r) => ({
          value: r,
          text: r.name,
        }));
      });
    },
    /**
     * Assigns wiki data in the store depending on the wiki
     */
    setWikiData() {
      this.reportDataStore.wiki = this.wiki;
      this.reportDataStore.folderName = (this.wiki && this.wiki.projectId !== this.selectedProject.id ? `${this.selectedProject.id}` : '');
    },
    /**
     * Assigns the number of user stories per state in the whole project
     * @returns {Promise<Awaited<unknown>[]>}
     */
    getUserStoriesPerState() {
      return this.apiStore.itemsApi.queryByWiql({
        query:
            `SELECT [System.ID]
            FROM WorkItems
            WHERE [System.TeamProject] = '${this.selectedProject.name}'
            AND [System.WorkItemType] = 'User Story'`,
      }).then((s) => {
        const userStoryIds = s.workItems.map((item) => item.id);
        const idsInChuck = chunkArray(userStoryIds);
        const chunkPromises = [];
        idsInChuck.forEach((chunk) => {
          const chunkPromise = this.apiStore.itemsApi.getWorkItems(chunk)
            .then((userStories) => {
              userStories.forEach((userStory) => {
                const state = userStory.fields['System.State'];
                const index = this.userStoriesPerState.findIndex((x) => x.state === state);
                if (index === -1) {
                  this.userStoriesPerState.push({
                    state,
                    count: 1,
                  });
                } else {
                  this.userStoriesPerState[index].count += 1;
                }
              });
            });
          chunkPromises.push(chunkPromise);
        });
        return Promise.all(chunkPromises);
      });
    },
    /**
     * Get iteration capacity
     * Assigns the time stats + other info in the hierarchy of the items of the chosen iteration
     * @returns {Promise<Awaited<unknown>[]>}
     */
    getIterationData() {
      const iterationPromises = [];

      iterationPromises.push(
        this.apiStore.workApi.getCapacitiesWithIdentityRefAndTotals({
          projectId: this.selectedProject.id,
          teamId: this.selectedTeam.id,
        }, this.selectedIteration.id)
          .then((result) => {
            this.iterationCapacities = result;
          }),
      );

      iterationPromises.push(
        this.apiStore.workApi.getTeamDaysOff({
          projectId: this.selectedProject.id,
          teamId: this.selectedTeam.id,
        }, this.selectedIteration.id)
          .then((result) => {
            this.teamDaysOff = result;
          }),
      );

      /**
       * Get full hierarchy from child iteration
       * Don't query with specific parent WorkItemType
       * to get all items even those without parents that are in iteration
       * "System.LinkTypes.Hierarchy-Forward" source = parent and target = child
       *
       * Azure query
       * Type of query: Tree of work items
       * Filter options: Matching linked items first
       * Type of tree: Parent/Child
       */
      iterationPromises.push(
        this.apiStore.itemsApi.queryByWiql({
          query: `SELECT [System.ID]
            FROM WorkItemLinks
            WHERE (
              [Source].[System.TeamProject] = '${this.selectedProject.name}'
              AND [System.Links.LinkType] = 'System.LinkTypes.Hierarchy-Forward'
              AND [Target].[System.TeamProject] = '${this.selectedProject.name}'
              AND [Target].[System.IterationPath] = '${this.selectedIteration.path}'
            )
            ORDER BY [System.Id]
            MODE(Recursive, ReturnMatchingChildren)`,
        }).then((s) => {
          // Get all items ids
          const ids = [];
          s.workItemRelations.forEach((relation) => {
            if (relation.source && ids.indexOf(relation.source.id) === -1) {
              ids.push(relation.source.id);
            }
            if (ids.indexOf(relation.target.id) === -1) {
              ids.push(relation.target.id);
            }
          });

          // Get all items
          const idsChuck = chunkArray(ids);
          const chunkPromises = [];
          const items = [];
          idsChuck.forEach((batchIds) => {
            chunkPromises.push(this.apiStore.itemsApi.getWorkItems(batchIds, null, null, 'Links')
              .then((itemsBatch) => {
                items.push(...itemsBatch);
              }));
          });

          // Once we have all items we can loop them
          // we check if have parent in hierarchy and get parent type from items
          return Promise.all(chunkPromises)
            .then(() => {
              // Set default US/PBI data
              items.filter((item) => ['User Story', 'Product Backlog Item'].includes(item.fields['System.WorkItemType'])).forEach((item) => {
                if (this.iterationData.findIndex((x) => x.id === item.id) === -1) {
                  const state = item.fields['System.State'];
                  const isClosed = CLOSED_STATES.includes(state);
                  const storyPoints = (typeof item.fields['Microsoft.VSTS.Scheduling.StoryPoints'] !== 'undefined') ? item.fields['Microsoft.VSTS.Scheduling.StoryPoints'] : 0;
                  const effort = (typeof item.fields['Microsoft.VSTS.Scheduling.Effort'] !== 'undefined') ? item.fields['Microsoft.VSTS.Scheduling.Effort'] : 0;
                  const tags = typeof item.fields['System.Tags'] !== 'undefined' ? item.fields['System.Tags'].split(';').map((tag) => tag.trim()) : [];
                  const isCR = tags.indexOf('CR') !== -1 || tags.indexOf('Change Request') !== -1;
                  this.iterationData.push({
                    id: item.id,
                    title: item.fields['System.Title'],
                    url: item._links.html.href,
                    estimated: 0,
                    completed: 0,
                    remaining: 0,
                    onBugs: 0,
                    onDefects: 0,
                    onTasks: 0,
                    nbBugs: 0,
                    nbDefects: 0,
                    isClosed,
                    storyPoints,
                    effort,
                    isCR,
                    state,
                    currentIteration: item.fields['System.IterationPath'] === this.selectedIteration.path,
                    type: item.fields['System.WorkItemType'] === 'User Story' ? 'us' : 'pbi',
                  });
                }
              });

              // Set default features data
              items.filter((item) => item.fields['System.WorkItemType'] === 'Feature').forEach((item) => {
                if (this.iterationFeaturesData.findIndex((x) => x.id === item.id) === -1) {
                  this.iterationFeaturesData.push({
                    id: item.id,
                    title: item.fields['System.Title'],
                    url: item._links.html.href,
                    nbBugs: 0,
                    nbDefects: 0,
                  });
                }
              });

              // All iterations items
              // In case parents are from other iteration so does not count them in this one data
              items.filter((item) => item.fields['System.IterationPath'] === this.selectedIteration.path).forEach((item) => {
                // Find parents
                const itemParents = [];
                let checkForParent = true;
                let relationCheckChildId = item.id;
                while (checkForParent) {
                  const itemRelationIndex = s.workItemRelations.findIndex(
                    // eslint-disable-next-line no-loop-func
                    (x) => x.target.id === relationCheckChildId,
                  );

                  checkForParent = false;

                  // Found relation
                  if (itemRelationIndex !== -1) {
                    let parentId;
                    // Have an other parent in relations
                    if (s.workItemRelations[itemRelationIndex].source) {
                      parentId = s.workItemRelations[itemRelationIndex].source.id;
                      relationCheckChildId = parentId;
                    // No other parent this is the top one if not the current item
                    } else if (s.workItemRelations[itemRelationIndex].target.id !== item.id) {
                      parentId = s.workItemRelations[itemRelationIndex].target.id;
                    }

                    // Get parent item
                    if (typeof parentId !== 'undefined') {
                      const parent = items.find(
                        (x) => x.id === parentId,
                      );

                      if (typeof parent !== 'undefined') {
                        // If have an other parent in relations then continue to check
                        if (s.workItemRelations[itemRelationIndex].source) {
                          checkForParent = true;
                        }

                        itemParents.push(parent);
                      }
                    }
                  }
                }

                // Item data
                const type = item.fields['System.WorkItemType'];
                const isClosed = CLOSED_STATES.includes(item.fields['System.State']);
                const remaining = (!isClosed && typeof item.fields['Microsoft.VSTS.Scheduling.RemainingWork'] !== 'undefined') ? item.fields['Microsoft.VSTS.Scheduling.RemainingWork'] : 0;
                const completed = typeof item.fields['Microsoft.VSTS.Scheduling.CompletedWork'] !== 'undefined' ? item.fields['Microsoft.VSTS.Scheduling.CompletedWork'] : 0;
                const estimated = typeof item.fields['Microsoft.VSTS.Scheduling.OriginalEstimate'] !== 'undefined' ? item.fields['Microsoft.VSTS.Scheduling.OriginalEstimate'] : 0;

                // Task under bug
                const isTaskUnderBug = (type === 'Task' && itemParents.filter((parent) => parent.fields['System.WorkItemType'] === 'Bug').length > 0);
                const isTaskUnderDefect = (type === 'Task' && itemParents.filter((parent) => parent.fields['System.WorkItemType'] === 'Defect').length > 0);

                // Update US/PBI data
                itemParents.filter((parent) => ['User Story', 'Product Backlog Item'].includes(parent.fields['System.WorkItemType'])).forEach((parent) => {
                  const parentIndex = this.iterationData.findIndex((x) => x.id === parent.id);
                  if (parentIndex !== -1) {
                    this.iterationData[parentIndex].estimated += estimated;
                    this.iterationData[parentIndex].completed += completed;
                    this.iterationData[parentIndex].remaining += remaining;
                    this.iterationData[parentIndex].onBugs += (type === 'Bug' || isTaskUnderBug) ? completed : 0;
                    this.iterationData[parentIndex].onDefects += (type === 'Defect' || isTaskUnderDefect) ? completed : 0;
                    this.iterationData[parentIndex].onTasks += (type === 'Task' && !isTaskUnderBug && !isTaskUnderDefect) ? completed : 0;
                    if (type === 'Bug') {
                      this.iterationData[parentIndex].nbBugs += 1;
                    }
                    if (type === 'Defect') {
                      this.iterationData[parentIndex].nbDefects += 1;
                    }
                  }
                });

                // Bugs
                if (type === 'Bug' || isTaskUnderBug) {
                  this.timeOnBugs += completed;
                }
                if (type === 'Bug') {
                  // Update features data
                  // Currently feature only count bugs
                  itemParents.filter((parent) => parent.fields['System.WorkItemType'] === 'Feature').forEach((parent) => {
                    const parentIndex = this.iterationFeaturesData
                      .findIndex((x) => x.id === parent.id);
                    if (parentIndex !== -1) {
                      this.iterationFeaturesData[parentIndex].nbBugs += 1;
                    }
                  });

                  const severity = parseFloat(item.fields['Microsoft.VSTS.Common.Severity'].charAt(0));
                  if (typeof this.iterationBugs[severity - 1] === 'undefined') {
                    this.iterationBugs[severity - 1] = { open: 0, closed: 0 };
                  }
                  if (isClosed) {
                    this.iterationBugs[severity - 1].closed += 1;
                  } else {
                    this.iterationBugs[severity - 1].open += 1;
                  }
                }

                // Defects
                if (type === 'Defect' || isTaskUnderDefect) {
                  this.timeOnDefects += completed;
                }
                if (type === 'Defect') {
                  // Update features data
                  // Currently feature only count defects
                  itemParents.filter((parent) => parent.fields['System.WorkItemType'] === 'Feature').forEach((parent) => {
                    const parentIndex = this.iterationFeaturesData
                      .findIndex((x) => x.id === parent.id);
                    if (parentIndex !== -1) {
                      this.iterationFeaturesData[parentIndex].nbDefects += 1;
                    }
                  });

                  const severity = parseFloat(item.fields['Microsoft.VSTS.Common.Severity'].charAt(0));
                  if (typeof this.iterationDefects[severity - 1] === 'undefined') {
                    this.iterationDefects[severity - 1] = { open: 0, closed: 0 };
                  }
                  if (isClosed) {
                    this.iterationDefects[severity - 1].closed += 1;
                  } else {
                    this.iterationDefects[severity - 1].open += 1;
                  }
                }

                // Risks / blockers
                if (type === 'Feature Blocker' || type === 'Impediment') {
                  this.iterationRisksData.push({
                    id: item.id,
                    title: item.fields['System.Title'],
                    url: item._links.html.href,
                  });

                  if (type === 'Impediment') {
                    const priority = item.fields['Microsoft.VSTS.Common.Priority'];
                    if (typeof this.iterationImpediments[priority - 1] === 'undefined') {
                      this.iterationImpediments[priority - 1] = { open: 0, closed: 0 };
                    }
                    if (isClosed) {
                      this.iterationImpediments[priority - 1].closed += 1;
                    } else {
                      this.iterationImpediments[priority - 1].open += 1;
                    }
                  }
                }

                // peopleTimeStats
                if (completed) {
                  const person = { id: 0, name: 'Undefined', completed };
                  if (typeof item.fields['System.AssignedTo'] !== 'undefined') {
                    person.id = item.fields['System.AssignedTo'].id;
                    person.name = item.fields['System.AssignedTo'].displayName;
                  }
                  const index = this.peopleTimeStats.findIndex((x) => x.id === person.id);
                  if (index === -1) {
                    this.peopleTimeStats.push(person);
                  } else {
                    this.peopleTimeStats[index].completed += completed;
                  }
                }
              });
            });
        }),
      );

      return Promise.all(iterationPromises);
    },
    /**
     * Get previous iterations completed work
     */
    getPreviousIterationsData() {
      const iterationPromises = [];
      const previousIterations = this.teamIterations.filter((it) => it.attributes.startDate < this.selectedIteration.attributes.startDate);

      previousIterations.forEach((iteration) => {
        iterationPromises.push(
          this.apiStore.itemsApi.queryByWiql({
            query: `SELECT [System.ID]
              FROM WorkItemLinks
              WHERE (
                [Source].[System.TeamProject] = '${this.selectedProject.name}'
                AND [System.Links.LinkType] = 'System.LinkTypes.Hierarchy-Forward'
                AND [Target].[System.TeamProject] = '${this.selectedProject.name}'
                AND [Target].[System.IterationPath] = '${iteration.path}'
              )
              ORDER BY [System.Id]
              MODE(Recursive, ReturnMatchingChildren)`,
          }).then((s) => {
            // Get all items ids
            const ids = [];
            s.workItemRelations.forEach((relation) => {
              if (relation.source && ids.indexOf(relation.source.id) === -1) {
                ids.push(relation.source.id);
              }
              if (ids.indexOf(relation.target.id) === -1) {
                ids.push(relation.target.id);
              }
            });

            // Get all items
            const idsChuck = chunkArray(ids);
            const chunkPromises = [];
            const items = [];
            idsChuck.forEach((batchIds) => {
              chunkPromises.push(this.apiStore.itemsApi.getWorkItems(batchIds, null, null, 'Links')
                .then((itemsBatch) => {
                  items.push(...itemsBatch);
                }));
            });

            // Once we have all items we can loop them
            // we check if have parent in hierarchy and get parent type from items
            return Promise.all(chunkPromises)
              .then(() => {
                // // Set default US/PBI data
                items.filter((item) => ['User Story', 'Product Backlog Item'].includes(item.fields['System.WorkItemType'])).forEach((item) => {
                  if (this.previousIterationsData.findIndex((x) => x.id === item.id) === -1) {
                    const state = item.fields['System.State'];
                    const isClosed = CLOSED_STATES.includes(state);
                    const storyPoints = (typeof item.fields['Microsoft.VSTS.Scheduling.StoryPoints'] !== 'undefined') ? item.fields['Microsoft.VSTS.Scheduling.StoryPoints'] : 0;
                    const effort = (typeof item.fields['Microsoft.VSTS.Scheduling.Effort'] !== 'undefined') ? item.fields['Microsoft.VSTS.Scheduling.Effort'] : 0;
                    const tags = typeof item.fields['System.Tags'] !== 'undefined' ? item.fields['System.Tags'].split(';').map((tag) => tag.trim()) : [];
                    const isCR = tags.indexOf('CR') !== -1 || tags.indexOf('Change Request') !== -1;
                    this.previousIterationsData.push({
                      id: item.id,
                      title: item.fields['System.Title'],
                      url: item._links.html.href,
                      estimated: 0,
                      completed: 0,
                      remaining: 0,
                      onBugs: 0,
                      onDefects: 0,
                      onTasks: 0,
                      nbBugs: 0,
                      nbDefects: 0,
                      isClosed,
                      storyPoints,
                      effort,
                      isCR,
                      state,
                      currentIteration: item.fields['System.IterationPath'] === this.selectedIteration.path,
                      type: item.fields['System.WorkItemType'] === 'User Story' ? 'us' : 'pbi',
                    });
                  }
                });

                // All iterations items
                // In case parents are from other iteration so does not count them in this one data
                items.filter((item) => item.fields['System.IterationPath'] === iteration.path).forEach((item) => {
                  // Find parents
                  const itemParents = [];
                  let checkForParent = true;
                  let relationCheckChildId = item.id;
                  while (checkForParent) {
                    const itemRelationIndex = s.workItemRelations.findIndex(
                      // eslint-disable-next-line no-loop-func
                      (x) => x.target.id === relationCheckChildId,
                    );

                    checkForParent = false;

                    // Found relation
                    if (itemRelationIndex !== -1) {
                      let parentId;
                      // Have an other parent in relations
                      if (s.workItemRelations[itemRelationIndex].source) {
                        parentId = s.workItemRelations[itemRelationIndex].source.id;
                        relationCheckChildId = parentId;
                      // No other parent this is the top one if not the current item
                      } else if (s.workItemRelations[itemRelationIndex].target.id !== item.id) {
                        parentId = s.workItemRelations[itemRelationIndex].target.id;
                      }

                      // Get parent item
                      if (typeof parentId !== 'undefined') {
                        const parent = items.find(
                          (x) => x.id === parentId,
                        );

                        if (typeof parent !== 'undefined') {
                          // If have an other parent in relations then continue to check
                          if (s.workItemRelations[itemRelationIndex].source) {
                            checkForParent = true;
                          }

                          itemParents.push(parent);
                        }
                      }
                    }
                  }

                  // Item data
                  const type = item.fields['System.WorkItemType'];
                  const isClosed = CLOSED_STATES.includes(item.fields['System.State']);
                  const remaining = (!isClosed && typeof item.fields['Microsoft.VSTS.Scheduling.RemainingWork'] !== 'undefined') ? item.fields['Microsoft.VSTS.Scheduling.RemainingWork'] : 0;
                  const completed = typeof item.fields['Microsoft.VSTS.Scheduling.CompletedWork'] !== 'undefined' ? item.fields['Microsoft.VSTS.Scheduling.CompletedWork'] : 0;
                  const estimated = typeof item.fields['Microsoft.VSTS.Scheduling.OriginalEstimate'] !== 'undefined' ? item.fields['Microsoft.VSTS.Scheduling.OriginalEstimate'] : 0;

                  // Task under bug
                  const isTaskUnderBug = (type === 'Task' && itemParents.filter((parent) => parent.fields['System.WorkItemType'] === 'Bug').length > 0);
                  const isTaskUnderDefect = (type === 'Task' && itemParents.filter((parent) => parent.fields['System.WorkItemType'] === 'Defect').length > 0);

                  // Update US/PBI data
                  itemParents.filter((parent) => ['User Story', 'Product Backlog Item'].includes(parent.fields['System.WorkItemType'])).forEach((parent) => {
                    const parentIndex = this.previousIterationsData.findIndex((x) => x.id === parent.id);
                    if (parentIndex !== -1) {
                      this.previousIterationsData[parentIndex].estimated += estimated;
                      this.previousIterationsData[parentIndex].completed += completed;
                      this.previousIterationsData[parentIndex].remaining += remaining;
                      this.previousIterationsData[parentIndex].onBugs += (type === 'Bug' || isTaskUnderBug) ? completed : 0;
                      this.previousIterationsData[parentIndex].onDefects += (type === 'Defect' || isTaskUnderDefect) ? completed : 0;
                      this.previousIterationsData[parentIndex].onTasks += (type === 'Task' && !isTaskUnderBug && !isTaskUnderDefect) ? completed : 0;
                      if (type === 'Bug') {
                        this.previousIterationsData[parentIndex].nbBugs += 1;
                      }
                      if (type === 'Defect') {
                        this.previousIterationsData[parentIndex].nbDefects += 1;
                      }
                    }
                  });
                });
              });
          }),
        );
      });

      return Promise.all(iterationPromises);
    },
    /**
     * Assigns the time stats for the entire project and for each iteration
     * @returns {Promise<Awaited<unknown>[]>}
     */
    getTimeStats() {
      return this.apiStore.itemsApi.queryByWiql({
        query: `SELECT [System.ID]
            FROM WorkItems
            WHERE [System.TeamProject] = '${this.selectedProject.name}'
            AND [System.WorkItemType] IN ('Bug', 'Defect', 'Task')`,
      }).then((s) => {
        const ids = s.workItems.map((item) => item.id);
        const idsChuck = chunkArray(ids);
        const chunkPromises = [];
        idsChuck.forEach((batchIds) => {
          chunkPromises.push(this.apiStore.itemsApi.getWorkItems(batchIds)
            .then((items) => {
              items.forEach((item) => {
                const type = item.fields['System.WorkItemType'];
                const state = item.fields['System.State'];
                const remainingWork = (!CLOSED_STATES.includes(state) && typeof item.fields['Microsoft.VSTS.Scheduling.RemainingWork'] !== 'undefined') ? item.fields['Microsoft.VSTS.Scheduling.RemainingWork'] : 0;
                const completedWork = typeof item.fields['Microsoft.VSTS.Scheduling.CompletedWork'] !== 'undefined' ? item.fields['Microsoft.VSTS.Scheduling.CompletedWork'] : 0;
                const originalEstimate = typeof item.fields['Microsoft.VSTS.Scheduling.OriginalEstimate'] !== 'undefined' ? item.fields['Microsoft.VSTS.Scheduling.OriginalEstimate'] : 0;

                this.projectTimeStats.estimated += originalEstimate;
                this.projectTimeStats.completed += completedWork;
                this.projectTimeStats.remaining += remainingWork;
                this.projectTimeStats.onBugs += (type === 'Bug') ? completedWork : 0;
                this.projectTimeStats.onDefects += (type === 'Defect') ? completedWork : 0;
                this.projectTimeStats.onTasks += (type === 'Task') ? completedWork : 0;

                const iterationPath = item.fields['System.IterationPath'];
                const index = this.iterationsTimeStats.findIndex((x) => x.path === iterationPath);
                if (index === -1) {
                  this.iterationsTimeStats.push({
                    path: iterationPath,
                    estimated: originalEstimate,
                    completed: completedWork,
                    remaining: remainingWork,
                    onBugs: (type === 'Bug') ? completedWork : 0,
                    onDefects: (type === 'Defect') ? completedWork : 0,
                    onTasks: (type === 'Task') ? completedWork : 0,
                  });
                } else {
                  this.iterationsTimeStats[index].estimated += originalEstimate;
                  this.iterationsTimeStats[index].completed += completedWork;
                  this.iterationsTimeStats[index].remaining += remainingWork;
                  this.iterationsTimeStats[index].onBugs += (type === 'Bug') ? completedWork : 0;
                  this.iterationsTimeStats[index].onDefects += (type === 'Defect') ? completedWork : 0;
                  this.iterationsTimeStats[index].onTasks += (type === 'Task') ? completedWork : 0;
                }
              });
            }));
        });
        return Promise.all(chunkPromises);
      });
    },
    /**
     * Calculates the remaining work hours left for the weeks prior to the selected iteration.
     * The burndown data is sorted chronologically.
     * @returns {Promise<Awaited<unknown>[]>}
     */
    getBurndownData() {
      let endDate = new Date(this.selectedIteration.attributes.finishDate);
      if (endDate.toString() === 'Invalid Date') endDate = new Date();
      endDate.setHours(23, 59, 59);
      while (endDate.getDay() !== 5) { // Friday
        endDate.setDate(endDate.getDate() - 1);
      }
      const startDate = addDays(endDate, -(MAX_BURNDOWN_WEEKS - 1) * 7);

      const datePromises = [];
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 7)) {
        const datePromise = this.apiStore.itemsApi.queryByWiql({
          query: `SELECT [System.ID]
            FROM WorkItems
            WHERE [System.TeamProject] = '${this.selectedProject.name}'
            AND [System.WorkItemType] IN ('Bug', 'Defect', 'Task')
            AND [System.State] NOT IN ('${CLOSED_STATES.join("', '")}')
            AND [Microsoft.VSTS.Scheduling.RemainingWork] > 0
            AsOf '${date.toISOString()}'`,
        }).then((query) => {
          const chunkPromises = [];
          const itemIds = query.workItems.map((item) => item.id);
          const idsInChunk = chunkArray(itemIds); // max size allowed by ADO
          idsInChunk.forEach((chunk) => {
            const chunkPromise = this.apiStore.itemsApi.getWorkItems(chunk, null, query.asOf)
              .then((items) => {
                let remainingWork = 0;
                items.forEach((item) => {
                  remainingWork += typeof item.fields['Microsoft.VSTS.Scheduling.RemainingWork'] !== 'undefined' ? item.fields['Microsoft.VSTS.Scheduling.RemainingWork'] : 0;
                });
                const index = this.burndownData.findIndex((x) => x.date === query.asOf);
                if (index === -1) {
                  this.burndownData.push({
                    date: query.asOf,
                    remainingWork,
                  });
                } else {
                  this.burndownData[index].remainingWork += remainingWork;
                }
              });
            chunkPromises.push(chunkPromise);
          });
          return Promise.all(chunkPromises);
        });
        datePromises.push(datePromise);
      }
      return Promise.all(datePromises).then(() => {
        this.burndownData.sort((x, y) => ((x.date > y.date) ? 1 : -1));
      });
    },
    openTab() {
      // eslint-disable-next-line max-len
      window.open('https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate?toc=%2Fazure%2Fdevops%2Forganizations%2Ftoc.json&view=azure-devops&tabs=Windows', '_blank');
    },
    clearOrganizationInput() {
      this.organizationName = '';
      this.personalAccessToken = '';
      this.organizationInputError = null;
    },
    clearNewPersonalAccessToken() {
      this.newPersonalAccessToken = '';
      this.tokenUpdateInputError = null;
    },
    clearAllData() {
      this.clearOrganizationSpecificData();

      this.loading = false;
      this.reportDataStore.organization = null;
      this.selectedOrganization = null;
    },
    clearOrganizationSpecificData() {
      this.clearProjectSpecificData();

      this.projectOptions = [];
      this.reportDataStore.project = null;
      this.selectedProject = null;
    },
    clearProjectSpecificData() {
      this.clearTeamSpecificData();

      this.teamsOptions = null;
      this.reportDataStore.wiki = null;
      this.reportDataStore.folderName = '';
      this.wiki = null;
      this.reportStartDate = null;
      this.reportDataStore.team = null;
      this.selectedTeam = null;
    },
    clearTeamSpecificData() {
      this.clearIterationSpecificData();

      this.iterationOptions = [];
      this.reportDataStore.iteration = null;
      this.selectedIteration = null;
    },
    clearIterationSpecificData() {
      this.burndownData = [];
      this.userStoriesPerState = [];
      this.iterationsTimeStats = [];
      this.iterationCapacities = null;
      this.iterationData = [];
      this.previousIterationsData = [];
      this.iterationFeaturesData = [];
      this.iterationRisksData = [];
      this.iterationImpediments = [];
      this.timeOnBugs = 0;
      this.timeOnDefects = 0;
      this.peopleTimeStats = [];
      this.projectTimeStats = {
        estimated: 0, completed: 0, remaining: 0, onBugs: 0, onDefects: 0, onTasks: 0,
      };
      this.iterationBugs = [];
      this.iterationDefects = [];
    },
    loadMarkdown() {
      if (!this.selectedIteration) return;
      this.clearIterationSpecificData();

      this.reportDataStore.iteration = this.selectedIteration;
      this.reportDataStore.timestamp = new Date().getTime();
      this.loading = true;

      const iterationPromises = [];
      iterationPromises.push(this.getIterationData());
      iterationPromises.push(this.getPreviousIterationsData());

      Promise.all(iterationPromises).then(() => { this.loading = false; });
    },
    showInternalMarkdown() {
      this.isExternalReport = false;
      this.internalBtnVariant = 'primary';
      this.externalBtnVariant = 'outline';
    },
    showExternalMarkdown() {
      this.isExternalReport = true;
      this.internalBtnVariant = 'outline';
      this.externalBtnVariant = 'primary';
    },
  },
  created() {
    this.clearAllData();
    this.getOrganizationOptions();
  },
  watch: {
    async selectedOrganization() {
      if (!this.selectedOrganization) return;
      this.clearOrganizationSpecificData();

      const organization = this.organizationOptions
        .find((opt) => opt.value === this.selectedOrganization);

      let bearerToken = null;
      let secretKey = '';
      let decryptedPAT = '';

      if (organization !== null) {
        if (organization.value === NVENTIVE_ORG) {
          bearerToken = await this.apiStore.getBearerAccessToken();
        }
        if (bearerToken !== null) {
          await this.apiStore.updateToken(bearerToken, organization.value);
        } else {
          secretKey = process.env.VUE_APP_ENCRYPTION_DECRYPTION_KEY;
          decryptedPAT = decryptToken(organization.key, secretKey);
          await this.apiStore.updateToken(decryptedPAT, organization.value);
        }
        this.reportDataStore.organization = this.selectedOrganization;
      }
      this.getProjectOptions();
    },
    selectedProject() {
      if (!this.selectedProject) return;
      this.clearProjectSpecificData();

      this.reportDataStore.project = this.selectedProject;

      this.getTeams();
    },
    selectedTeam() {
      if (!this.selectedTeam) return;
      this.clearTeamSpecificData();

      this.reportDataStore.team = this.selectedTeam;

      this.getIterations();
    },
    selectedIteration() {
      this.loadMarkdown();
    },
    isExternalReport() {
      this.loadMarkdown();
    },
  },
};
</script>
